import _ from 'lodash';

const maxCol = 5;

const QuizPage = {
  data() {
    return {
      questions: [],
      testCode: '',
      subject: '',
      questionIndex: 0,
      answers: {},
      boardData: [],
      time: 1 * 60 * 60,
      visibleMenu: false,
      rating: 0,
    };
  },

  computed: {
    currentQuestion() {
      return this.questions?.[this.questionIndex];
    },

    rowCount() {
      return Math.ceil(this.questions.length / maxCol);
    },

    timeText() {
      return new Date(this.time * 1000).toISOString().substr(11, 8);
    },
  },

  created() {
    this.firebase = this.$firebase.rt.ref('user_tracking/' + this.$authentication.userInfo.email.replace('@gmail.com', '').replaceAll('.', '!'));

    this.unsubscribeList = [];

    this.quizCode = this.$route.params.id;
    const quizCodeSegments = this.quizCode.split('_');
    this.testCode = quizCodeSegments[1];

    if (!this.$store.state.SubjectList) {
      this.$subject.ksaSubjectsDoc.onSnapshot(snapshot => {
        let data = snapshot.data();
        this.subject = data[quizCodeSegments[0]];
        this.$store.commit('INIT', data);
      });
    } else {
      this.subject = this.$store.state.SubjectList[quizCodeSegments[0]];
      this.$store.commit('RESET_TEST');
    }

    this.intervalId = setInterval(() => {
      this.time--;

      if (this.time <= 0) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;

        this.submit();
      }
    }, 1000);

    this.$firebase.db
      .collection('quiz')
      .doc(this.quizCode)
      .get()
      .then(res => {
        this.questions = Object.values(res.data());

        const numbers = _.range(1, this.questions.length + 1);
        this.boardData = _.chunk(numbers, 5);

        this.$store.commit('SET_TEST_CODE', { id: this.quizCode + '_' + Date.now(), total: this.questions.length });
      })
      .catch(e => {
        const message = e?.message || 'Có lỗi rồi';
        this.$toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
      });
  },

  beforeDestroy() {
    this.unsubscribeList.forEach(unsubscribe => unsubscribe());

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    toggleMenu() {
      this.visibleMenu = !this.visibleMenu;
    },

    formatTime(value) {
      if (value >= 10) {
        return value.toString();
      }

      return `0${value}`;
    },

    getAnswers(question) {
      return Object.keys(question.answers)
        .sort()
        .map(answer => {
          return {
            text: question.answers[answer],
            value: answer,
          };
        });
    },

    selectAnswer(questionId, chapter, answer) {
      if (this.answers[questionId] === answer) {
        delete this.answers[questionId];
        const answerObj = {
          id: questionId,
        };
        this.$store.commit('REMOVE_ANSWER2', answerObj);
      } else {
        this.answers[questionId] = answer;

        const answerObj = {
          id: questionId,
          info: {
            selected: answer,
            correct: this.currentQuestion.correctAnswer,
            chapter: chapter,
          },
          correctNum: this.currentQuestion.correctAnswer === answer ? 1 : 0,
        };
        this.$store.commit('ADD_ANSWER2', answerObj);
      }

      this.answers = { ...this.answers };
    },

    isSelectAnswer(questionId, answer) {
      return this.answers[questionId] === answer;
    },

    isAnswered(questionIndex) {
      const question = this.questions[questionIndex];
      return !!this.answers[question.id];
    },

    getEmptyCellData(rowData) {
      const remain = rowData.length - maxCol;
      const result = _.range(0, remain);
      return result;
    },

    gotoQuestion(questionIndex) {
      this.questionIndex = questionIndex;
      this.$refs.quizRef.scrollTop = 0;
    },

    parseHtml(html) {
      return html.replaceAll('<p><br></p>', '');
    },

    nextQuestion() {
      this.questionIndex++;
      if (this.questionIndex >= this.questions.length) {
        this.questionIndex = 0;
      }
      this.$refs.quizRef.scrollTop = 0;
    },

    prevQuestion() {
      this.questionIndex--;
      if (this.questionIndex < 0) {
        this.questionIndex = this.questions.length - 1;
      }
      this.$refs.quizRef.scrollTop = 0;
    },

    submit() {
      let temp = this.$store.getters.getTest;
      let _answers = temp[this.$store.state.Subject.TestCode].Answers;

      const data = temp[Object.keys(temp)[0]];

      for (let i = 0; i < this.questions.length; i++) {
        if (_answers[i] && _answers[i].selected === _answers[i].correct) {
          data.CorrectNum++;
        }
      }

      if (_answers.includes(undefined) || _answers.length < this.questions.length) {
        for (let i = 0; i < this.questions.length; i++) {
          if (!_answers[i]) {
            let ans = {
              index: i,
              info: {
                selected: '',
                correct: this.questions[i].correctAnswer,
                chapter: this.questions[i].chapter,
              },
            };

            this.$store.commit('INSERT_EMPTY_ANSWER', ans);
          }
        }
      }

      temp[Object.keys(temp)[0]]['Rating'] = this.rating;

      this.firebase.child('submit').update(temp);

      this.$router.push({
        name: 'ResultV2',
        params: {
          resId: this.$store.state.Subject.TestCode,
        },
      });
    },
  },

  mounted() {
    document.title = 'Tabbook - Quiz';


  },
};

export default QuizPage;
